import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/Upper_Green.svg';
import Alerts from './alerts';

function showAlerts(count){
  if(count) return (<><Alerts count={count}/></>)
  return (<></>)
}

function Header(props) {
  return (
    <>
   {showAlerts(props.emergencycount)}
      <Navbar expand="lg" >
        <Container>
          <Navbar.Brand href="/">
          <img
              alt=""
              src={logo}
              width="100"
              className="d-inline-block align-top"
            />{' '}
            </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link href="/">Dashboard</Nav.Link>
            <Nav.Link target="_blank" href="https://tnrd.maps.arcgis.com/apps/webappviewer/index.html?id=5fb33103276a41c3bc4975dc20eb1219">Interactive Map</Nav.Link>
            <Nav.Link href="/archived">Archived Events</Nav.Link>
            <Nav.Link href="https://www.tnrd.ca/services/emergency-services/emergency-resources/" target="_blank">Resources</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;