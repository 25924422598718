import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Wrapper from '../components/wrapper';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import SpinnerTnrd from '../components/spinner';

import EmergencyList from '../templates/emergencyList';

import {currentEmergenciesContext} from '../components/root'

export function CurrentPage(props) {

    document.title = 'Current - TNRD EOC';

    const [emergencies, setEmergencies] = useState();

    useEffect(() => {
        props.crumbs([
        ['Current Emergencies','/current',true]
        ]
          )
        }, []);

    var em = useContext(currentEmergenciesContext);

    if(em) {
    return (
        <Wrapper page="Current">
            <div>
                <p>All current emergencies are listed below.</p>

<p>Please note that the TNRD only has jurisdiction to issue emergency notices for properties within the 10 TNRD Electoral Areas. For information regarding emergencies in other jurisdictions, such as First Nations or municipalities, please refer to the respective local government.</p>
            <Card>
                <Card.Header>Current Emergencies</Card.Header>
                <Card.Body>
                <ListGroup className="list-group-flush">
                <EmergencyList emergencies={em} archived={false}/>
                </ListGroup>
                </Card.Body>
            </Card>
            </div>
        </Wrapper>
    );} else {
        return(<Container><SpinnerTnrd/></Container>)
    }
  }