import React from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from './components/root'
import { ErrorBoundary } from 'react-error-boundary'

const root = ReactDOM.createRoot(document.getElementById('root'));

function MyFallbackComponent({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

root.render(<ErrorBoundary
    FallbackComponent={MyFallbackComponent}
    onReset={() => {
      
    }}
  ><Root/></ErrorBoundary>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
