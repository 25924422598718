import React from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';


function click(type){
  if(type == 1){
    window.location.href = 'https://'+window.location.host+'/current';
    return;
  }

  window.open('https://www.tnrd.ca/category/emergency/', '_blank').focus();
  
  return;

}

function Alerts(props) {
  return (
      
       <Row className="gx-0 top-alerts">
        <Col sm={12} lg={6}>
          <Alert className="alert-Emergency"  onClick={function(){click(1)}}>
          <p><strong>Current Emergencies ({props.count})</strong><br/>View current Emergency Notices in the Thompson-Nicola region</p>
          </Alert>
        </Col>
        <Col sm={12} lg={6}>
          <Alert className='alert-Information' onClick={function(){click(2)}}>
          <p><strong>Emergency Information</strong><br/>View all TNRD Press Releases on emergency preparation and recovery</p>
          </Alert>
        </Col>
       </Row>
      
  );

}


export default Alerts;