import React, { useState, useEffect } from "react";
import { useParams, redirect } from "react-router-dom";
import Wrapper from '../components/wrapper';
import NoticeDetail from '../templates/noticeDetail';
import parse from 'html-react-parser';
import SpinnerTnrd from '../components/spinner';


export function NoticePage(props) {
    const { notice_slug } = useParams();
    const [notice, setNotice] = useState();
    const [fetchError, setFetchError] = useState(false);
    var notice_id = notice_slug.split('-').pop();

    useEffect(() => {
        fetch('https://api.tnrd.ca/eoc/post/'+notice_id)
        .then(response => {
            if(!response.ok){
                throw new Error(response);
            }
            return response.json()
        })
        .then(data => {
          setNotice(data);
          props.crumbs([
            [parse(data.title.rendered),'',true]
            ])
        document.title = data.title.rendered+' - TNRD EOC';
      })
        .catch(error => {
            setFetchError(true);
            console.log(error)
        });

    },[]);

    if(fetchError){
        return(
            <Wrapper>
                <h2>Something Went Wrong</h2>
            </Wrapper>
        );
    }

    if(notice) {
    return (
        <Wrapper>
            <NoticeDetail notice={notice}/>
        </Wrapper>
    );} else {
        return (<SpinnerTnrd/>)
    }
  }