import React from "react";
import Container from 'react-bootstrap/Container';
import ContactInfo from '../templates/contactInfo';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from './sidebar';
import Card from 'react-bootstrap/Card';

function Wrapper(props) {
  function sidebar(){
    if(props && props.page === 'Current'){
      return(<><div className="sidebar_wrapper">
      <Card>
      <Card.Header>TNRD Emergency Operations Centre</Card.Header>
      <Card.Body>
        <ContactInfo />
      </Card.Body>
      </Card></div></>)
    }

    return (<Sidebar />)
  }

  return (
    <Container>
      <Row className="wrapper">
        <Col xs={{span:12,order:2}} lg={{span:4,order:1}} className="sidebar">{sidebar()}</Col>
        <Col xs={{span:12,order:1}} lg={{span:8,order:1}} className="mb-2 content">{props.children}</Col>
      </Row>
    </Container>
  );
}

export default Wrapper;