import conf from  '../conf.js';

import React, { Suspense } from "react";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import parse from 'html-react-parser';

function view_notice(e){
    var slug = e.slug?e.slug:e.post_name;
    window.open(window.location.origin+'/eoc-notice/'+slug+'-'+e.id, '_blank').focus();
}

function byline(notice,posted,status){
    if(status){
        return (
            <>Posted: {posted.toLocaleString([],conf.dateoptions)} |  Type: {notice_type(notice.notice_type)} | Status: {status_type(notice.notice_status)} </>
        )
    }
    return (
        <>Posted: {posted.toLocaleString([],conf.dateoptions)} |  Type: {notice_type(notice.notice_type)}</>
    )
    
}

function notice_type(types){
    if (!Array.isArray(types)){
        types = [types]
    }
    return types.map(function(type, i){
        var cla = "success";
        switch (type) {
            case 'Evacuation Alert':
                cla = "warning";
                break;
            case 'Evacuation Order':
                cla = 'danger';
                break;
            case 'Information':
                cla = 'info';
        };
        return <Badge className="type" bg={cla}>{type}</Badge>;
    })
  }

function status_type(status){
    var cla = "success";
    if(status == 'Archived'){
       cla = 'warning'; 
    }
    return <Badge className="status" bg={cla}>{status}</Badge>;
}

function NoticeList(props){
    var notices = props.notices;
    var status = props.status?props.status:false;
    return notices.map(function(notice, i){
        var posted = new Date(notice.date?notice.date:notice.post_date);
        return (
            <Suspense fallback={<p>loading</p>}>
          <ListGroup.Item onClick={() => {view_notice(notice)}}
          as="li"
          key={i}
          className="list_emergency d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{parse(notice.title?notice.title.rendered:notice.post_title)}</div>
            {byline(notice,posted,status)}
          </div>
        </ListGroup.Item>
        </Suspense>
        )
      });
}

export default NoticeList;