import React from "react";
import './styles/index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ReactGA from "react-ga4";
/*Pages*/
import { NoticePage } from './pages/notice';
import { NoticesPage } from './pages/notices';
import { Dashboard } from './pages/dashboard';
import { EmergencyPage } from './pages/emergency';
import { ArchivedPage } from './pages/archived';
import { CurrentPage } from './pages/current';
import { NotFound } from './pages/notfound';

ReactGA.initialize("G-PDH4BBW0S6");

new MutationObserver(function(mutations) {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname , title: mutations[0].target.nodeValue});
}).observe(
  document.querySelector('title'),
  { subtree: true, characterData: true, childList: true }
);

function App(props) {
  
  return (
    <div>
    <Router>
      <Routes>
      <Route path='*' element={<NotFound crumbs={props.crumbs}/>} />
      <Route path="/" element={<Dashboard crumbs={props.crumbs} />} />
      <Route path="/eoc-notice/:notice_slug" element={<NoticePage crumbs={props.crumbs} />} />
      <Route path="/notices/:type" element={<NoticesPage crumbs={props.crumbs} />} />
      <Route path="/notices/" element={<NoticesPage crumbs={props.crumbs} />} />
      <Route path="/eoc/:eoc_slug" element={<EmergencyPage crumbs={props.crumbs} />} />
      <Route path="/archived" element={<ArchivedPage crumbs={props.crumbs} />} />
      <Route path="/current" element={<CurrentPage crumbs={props.crumbs} />} />
      </Routes>
    </Router>
  </div>  
  );
}

export default App;
