import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Wrapper from '../components/wrapper';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import SpinnerTnrd from '../components/spinner';
import NoticeList from '../templates/noticeList';
import Filters from '../components/filterbar';

function applyFilters(notices){
  var ret = notices;
  var filters = {};
  const query = new URLSearchParams(window.location.search);
  if(query.get('filter') == 1){
      filters = {
      Type : query.get('type'),
      Status : query.get('status')
    }

    if(filters.Status && filters.Status != 'All'){
       ret = notices.filter((not) => not.notice_status == filters.Status);
    }

    if(filters.Type && filters.Type != 'All'){
      ret = notices.filter((not) => not.notice_type.includes(filters.Type));
    }

  }

  return [ret,filters];
}

function DisplayFilters(props){
  var activeFilters = [];
  if(Object.keys(props.filters).length > 0){
  activeFilters.push(<> | Active Filters</>);
  Object.keys(props.filters).forEach(function(key, index) {
    if(props.filters[key] != null){
    activeFilters.push(<>{key} : {props.filters[key]} </>);
    }
  })
}


  return (<> | Found: <strong>{props.notices.length}</strong></>)
}

function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}

export function NoticesPage(props) {
    var { type } = useParams();
    if(!type){
      type = 'All';
    } 
    
    document.title = capitalize(type)+' Notices - TNRD EOC'
    
    const [notices, setNotices] = useState();
    const [filters, setFilters] = useState();

    useEffect(() => {
        props.crumbs([
        [capitalize(type)+' Notices','/notices',true]
        ]
          )
        }, []);

    useEffect(() => {
    var url = 'https://api.tnrd.ca/eoc/notices/';
    if(type == 'current'){
      url = 'https://api.tnrd.ca/eoc/current/notices/';
    } else if (type == 'archived'){
      url = 'https://api.tnrd.ca/eoc/archived/notices/';
    }

        
    fetch(url)
    .then(response => response.json())
    .then(data => {
      var n = applyFilters(data)
      setNotices(n[0]);
      setFilters(n[1]);
  })
    .catch(error => console.error(error));
    }, []);

    

    if(notices) {
    return (
        <Wrapper page="Notices">
            <div>
            <Card>
                <Card.Header>{capitalize(type)} Notices</Card.Header>
                <Card.Body>
                <ListGroup className="list-group-flush">
                  <NoticeList notices={notices} status={true} />
                </ListGroup>
                </Card.Body>
            </Card>
            </div>
        </Wrapper>
    );} else {
        return(<Container><SpinnerTnrd/></Container>)
    }
  }