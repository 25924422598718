import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Wrapper from '../components/wrapper';
import EmergencyDetail from '../templates/emergencyDetail';
import Container from 'react-bootstrap/Container';
import SpinnerTnrd from '../components/spinner';


export function EmergencyPage(props) {
    const { eoc_slug } = useParams();
    const [emergency, setEmergency] = useState();
    const [fetchError, setFetchError] = useState(false);
    var emergency_id = eoc_slug.split('-').pop();
   
    useEffect(() => {
        fetch('https://api.tnrd.ca/eoc/post/'+emergency_id)
        .then(response => response.json())
        .then(data => {
            if(data.notices){

                data.notices.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.post_date) - new Date(a.post_date);
                  });
            }
            setEmergency(data);
            props.crumbs([
                [data.title.rendered,'',true]
                ])
            document.title = data.title.rendered+' - TNRD EOC';
        
      })
        .catch(error => {
            setFetchError(true);
        });

    }, []);

    if(fetchError){
        return(
            <Wrapper>
                <h2>Something Went Wrong</h2>
            </Wrapper>
        );
    }
    if(emergency) {
    return (
        <Wrapper>
            <div>
            <EmergencyDetail emergency={emergency}/>
            </div>
        </Wrapper>
    );} else {
        return(<Container><SpinnerTnrd/></Container>)
    }
  }