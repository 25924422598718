import React, { useContext } from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import EmergencyList from '../templates/emergencyList';
import ContactInfo from '../templates/contactInfo';
import SpinnerTnrd from './spinner';

import {currentEmergenciesContext,dateOptions} from '../components/root'

function Sidebar() {

  var em = useContext(currentEmergenciesContext);

  if(em){
    return (
      <div className="sidebar_wrapper">
        <Card>
        <Card.Header>TNRD Emergency Operations Centre</Card.Header>
        <Card.Body>
          <ContactInfo />
        </Card.Body>
        </Card>
        <Card className="mt-2">
        <Card.Header>Current Emergencies</Card.Header>
        <Card.Body>
        <ListGroup className="list-group-flush">
        <EmergencyList emergencies={em}/>
        </ListGroup>
        </Card.Body>
      </Card>
      </div>
    );
  } else {
    return (<SpinnerTnrd/>)
  }
}

export default Sidebar;