import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import SpinnerTnrd from '../components/spinner';

import { useParams } from "react-router-dom";
import Wrapper from '../components/wrapper';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import EmergencyList from '../templates/emergencyList';

export function ArchivedPage(props) {
  document.title = 'Archived - TNRD EOC';

    const [emergencies, setEmergencies] = useState();

    useEffect(() => {
        props.crumbs([
        ['Archived','/archived',true]
        ]
          )
        }, []);

    useEffect(() => {
        
    fetch('https://api.tnrd.ca/eoc/archived/events', {cache: "no-store"})
    .then(response => response.json())
    .then(data => {
      console.log(data);
      data.sort(function(a,b){
        return new Date(b.latest_notice[0].post_date) - new Date(a.latest_notice[0].post_date);
      })
      setEmergencies(data);
  })
    .catch(error => console.error(error));
    }, []);

    if(emergencies) {
    return (
        <Wrapper>
            <div>
            <Card>
                <Card.Header>Archived Emergencies</Card.Header>
                <Card.Body>
                <ListGroup className="list-group-flush">
                <EmergencyList emergencies={emergencies} archived={true}/>
                </ListGroup>
                </Card.Body>
            </Card>
            </div>
        </Wrapper>
    );} else {
       return(<Container><SpinnerTnrd/></Container>)
    }
  }