import conf from  '../conf.js';

import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';

import NoticeList from './noticeList';
import SpinnerTnrd from '../components/spinner';

function documents(documents){
    if(documents.length > 0){
        return documents.map(function(doc, i){
            var posted = new Date(doc.post_date);
            return (
                <Card>
                    <Card.Body>
                    <Card.Title as="h6">{doc.post_title}</Card.Title>
                    <Card.Text>
                        <Button className="btn-tnrd" href={doc.url} target="_blank" variant="primary"><i class="bi bi-download me-2"></i>Download</Button>
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">{posted.toLocaleString([],conf.dateoptions)}</small>
                    </Card.Footer>
                </Card>
            );
        })
    } else {    
        return (
            <><p>No documents found</p></>
        )
    }
}


function ReturnToEmergency(props){
    if(!props.emergencies){
        return (<></>)
    }
    return props.emergencies.map(function(emergency, i){
        return (
            <><a href={"/eoc/"+emergency.post_name+"-"+emergency.ID}>{emergency.post_title}</a></>
        )
    });
}

function notice_type(types){
    return types.map(function(type, i){

        switch (type) {
            case 'Evacuation Alert':
                var cla = "warning";
                break;
            case 'Evacuation Order':
                cla = 'danger';
                break;
            case 'Information':
                cla = 'info';
                break;
            default:
                cla = "success";
        };
        return <Badge className="type" bg={cla}>{type}</Badge>;
    })
}

function status_type(status){
    var cla = "success";
    if(status[0] === 'Archived'){
       cla = 'warning'; 
    }
    return <Badge className="status" bg={cla}>{status}</Badge>;
}


function RelatedNoticesCard(props){
    var notice = props.notice;
    var notices = props.notices;

    if(!notice.emergency){
        return(<></>);
    }

    var multi = '';

    if(notice.emergency.length > 1){
        multi = (<><br/>This notice is also related to <ReturnToEmergency emergencies={notice.emergency.slice(1)}/></>);
    }

    return (
        <Card className="mt-2">
            <Card.Header>Related Notices <small>({notices.length}) </small></Card.Header>
            <Card.Body>
                <ListGroup className="list-group-flush">
                    <NoticeList notices={notices} status={true}/>
                </ListGroup>
            </Card.Body>
        </Card>
    )
}

function NoticeDetail(props){
    var notice = props.notice;

    const [notices, setNotices] = useState();

    useEffect(() => {
    if(notice.emergency.length > 0){
        fetch('https://api.tnrd.ca/eoc/post/'+notice.emergency[0].ID)
        .then(response => response.json())
        .then(data => {
            setNotices(data.notices);
      })
        .catch(error => console.error(error));
    } else {
        setNotices([]);
    }
    }, []);

    var posted = new Date(notice.date);

    if(notice.data && notice.data.status !== 404){
        return (
            <div>
                <h1>Nothing Found</h1>
            </div>
        )
    }
   

    if(notices && notice){
        
        return (
            <div className="notice_wrapper">
                <h1 className="display-6">{parse(notice.title.rendered)}</h1>
                <div>
                    <p>Posted: <strong>{posted.toLocaleString([],conf.dateoptions)}</strong> | Type: {notice_type(notice.notice_type)} | Status: {status_type(notice.notice_status)} </p>
                </div>
                <p className="return_to"><ReturnToEmergency emergencies={notice.emergency}/></p>
                <div class="emergency_content">
                    {parse(notice.content.rendered)}
                </div>
                <div>
                <Button className="btn-tnrd" href="https://www.tnrd.ca/emergencymap" variant="primary"><i class="bi bi-box-arrow-up-left me-2"></i>View Emergency Map</Button>
                </div>
                <div className="document_wrapper">
                    <h4>Documents</h4>
                    <CardGroup>
                        {documents(notice._documents)}
                    </CardGroup>
                </div>
                <RelatedNoticesCard notices={notices} notice={notice}/>
            </div>
        )
    } else {
        return (<SpinnerTnrd/>)
    }
}

export default NoticeDetail;