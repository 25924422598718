import React, { useState, useEffect } from "react";
import '../index.css';
import App from '../App';
import Header from './header';
import Footer from './footer';
import Breadcrumbs from './breadcrumbs';

export const currentEmergenciesContext = React.createContext(null);
export const dateOptions = React.createContext(null);

function Root(){

const [breadcrumbs, setBreadcrumbs] = useState([]);
const [alertdisplay, setAlertdisplay] = useState(false);
const [currentEmergencies, setcurrentEmergencies] = useState(false);

    function breadcrumb_update(crumbs){
        setBreadcrumbs(crumbs);
    }

    const currentData = () => {
      fetch('https://api.tnrd.ca/eoc/current/events')
      .then(response => response.json())
      .then(data => {
        setcurrentEmergencies(data);
        alert_update(data.length);
    })
      .catch(error => console.error(error));
       }

    function alert_update(e){
      if(e > 0){
        setAlertdisplay(e);
      } else {
        setAlertdisplay(false);
      }
    }

    useEffect(() => {

      currentData();

    },[breadcrumbs]);

    return (
      <React.StrictMode>
        <currentEmergenciesContext.Provider value={currentEmergencies}>
          <Header emergencycount={alertdisplay} />
          <Breadcrumbs crumbs={breadcrumbs}/>
          <App crumbs={breadcrumb_update}/>
          <Footer />
        </currentEmergenciesContext.Provider>
      </React.StrictMode>
    )
}

export default Root;